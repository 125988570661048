import React, { useState } from 'react';

// === Components === //
import { Link } from 'gatsby';
import Container from '@components/common/Container';
import NavigationSwitch from '@components/layout/NavigationSwitch';
import LanguageSelector from '@components/layout/LanguageSelector';
import NavigationLogo from '@components/layout/NavigationLogo';

// === Content === //
import { patientsNavigationData } from '@graphql/PatientNavigation';
import { hcpData } from '@graphql/HcpNavigation';

import hamburgerIcon from '@assets/svg/hamburger.svg';
import closeIcon from '@assets/svg/close.svg';
import chevronRightIcon from '@assets/svg/chevron_right.svg';
import arrowTopRightIcon from '@assets/svg/arrow_top_right.svg';

// === Styles === //
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoints, colors, dimensions, mixins, respondFrom, respondTo } from '@styles';

// === Types === //
import { MenuData, Variant } from '@content/types/layout';

const NavigationWrapper = styled.div<AllProps>`
  position: relative;
  background-color: ${(props) =>
    props.variant === 'blue' ? colors.ui.patientDefault : colors.ui.hcpDefault};
  overflow-x: clip;
  width: 100vw;
`;

const NavigationInner = styled.div`
  display: flex;
  height: 53px;

  ${respondFrom(
    breakpoints.md,
    css`
      height: 70px;
    `
  )};
`;

const MenuWrapper = styled.div`
  flex: 1;

  ${respondTo(
    breakpoints.lg,
    css`
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      width: 325px;
      margin: auto;
      z-index: 20;
      background-color: ${colors.ui.patientDark};
      padding: 15px;
      overflow-y: auto;
      transform: translateX(80%);
      opacity: 0;
      visibility: hidden;
      ${mixins.transitionDefault}

      &.variant--red {
        background-color: ${colors.ui.hcpDark};
      }
    `
  )};

  &.is-open {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
`;

const Menu = styled.ul<AllProps>`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  z-index: 10;

  ${respondTo(
    breakpoints.lg,
    css`
      flex-flow: column;
      padding: 0 20px;
    `
  )};

  li {
    position: relative;
    text-align: center;
    height: 70px;

    ${respondTo(
      breakpoints.lg,
      css`
        height: auto;
        text-align: left;
        width: 100%;
      `
    )};

    &:hover {
      > .submenu {
        ${respondFrom(
          breakpoints.lg,
          css`
            opacity: 1;
            visibility: visible;
            ${mixins.transitionDefault};
          `
        )};
      }
    }
  }

  &.submenu {
    flex-flow: column;
    position: absolute;
    background: ${(props) =>
      props.variant === 'blue'
        ? colors.ui.patientDefaultTransparent
        : colors.ui.hcpDefaultTransparent};
    left: 0;
    right: 0;
    top: 100%;
    opacity: 0;
    visibility: hidden;

    ${respondTo(
      breakpoints.lg,
      css`
        opacity: 1;
        visibility: visible;
        position: static;
        display: none;
        background-color: transparent;
        padding: 0;
      `
    )};

    > li {
      width: 100%;
      height: auto;

      ${respondTo(
        breakpoints.lg,
        css`
          font-size: ${dimensions.fontSize.tiny}px;
        `
      )};

      > a {
        ${respondFrom(
          breakpoints.lg,
          css`
            padding: 10px 20px;
          `
        )};
      }
    }

    &.is-open {
      ${respondTo(
        breakpoints.lg,
        css`
          display: flex;
        `
      )};
    }
  }
`;

const MenuLink = styled(Link)<MenuLinkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: ${colors.white};
  text-decoration: none;
  font-size: ${dimensions.fontSize.small}px;
  padding: 0 20px;
  ${mixins.transitionDefault};
  text-align: center;
  height: 100%;
  text-transform: uppercase;
  min-width: 135px;
  margin: auto;
  text-align: center;

  ${respondTo(
    breakpoints.lg,
    css`
      padding: 20px 0;
      padding-right: 20px;
      display: inline-block;
      text-align: left;
    `
  )};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: auto;
    width: 1px;
    height: 25px;
    background-color: ${colors.transparentLight};
    margin: auto;
    display: ${(props) => (props.noborder === 'true' ? 'none' : 'block')};

    ${respondTo(
      breakpoints.lg,
      css`
        display: none;
      `
    )};
  }

  &:hover {
    ${respondFrom(
      breakpoints.lg,
      css`
        background-color: ${colors.ui.hcpDefault};
      `
    )};
  }

  &.submenu {
    text-transform: none;
    display: flex;
    justify-content: center;

    ${respondTo(
      breakpoints.lg,
      css`
        padding-left: 30px;
        font-size: ${dimensions.fontSize.tiny}px;
        display: inline-block;
      `
    )};

    &:hover {
      ${respondFrom(
        breakpoints.lg,
        css`
          background-color: ${colors.ui.hcpDefaultTransparent};
        `
      )};
    }
  }

  &.variant--red {
    &:hover {
      ${respondFrom(
        breakpoints.lg,
        css`
          background-color: ${colors.ui.patientDefault};
        `
      )};
    }

    &.submenu {
      &:hover {
        ${respondFrom(
          breakpoints.lg,
          css`
            background-color: ${colors.ui.patientDefaultTransparent};
          `
        )};
      }
    }
  }

  &.is-active {
    &::before {
      content: '';
      position: absolute;
      height: 3px;
      bottom: 6px;
      left: 20px;
      right: 20px;
      margin: auto;
      background-color: ${colors.white};
      z-index: -1;
      ${mixins.transitionDefault};

      ${respondTo(
        breakpoints.lg,
        css`
          left: 0;
          right: 0;
        `
      )};
    }
  }
`;

const MenuLinkOptions = styled.li`
  display: flex;
  align-items: center;
  flex-flow: column-reverse;

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-flow: row;
      margin-top: 0;
    `
  )};
`;

const Hamburger = styled.img`
  width: 30px;
  display: block;
  margin-left: auto;

  ${respondFrom(
    breakpoints.lg,
    css`
      display: none;
    `
  )};
`;

const MenuMobileTitle = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 20px;

  ${respondFrom(
    breakpoints.lg,
    css`
      display: none;
    `
  )};

  > img {
    display: inline-block;
    width: 25px;
  }

  > p {
    margin: 0 0 0 30px;
    color: ${colors.white};
    font-size: ${dimensions.fontSize.large}px;
    font-weight: 700;
    text-shadow: 0 6px 15px rgba(0, 0, 0, 0.63);
  }
`;

const MenuMobileChevron = styled.img`
  position: absolute;
  top: 5px;
  right: -15px;
  margin: auto;
  padding: 20px;

  ${respondFrom(
    breakpoints.lg,
    css`
      display: none;
    `
  )};

  &.is-open {
    transform: rotate(90deg);
  }
`;

const SubmenuLinkMobileIcon = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 6px;

  ${respondFrom(
    breakpoints.lg,
    css`
      display: none;
    `
  )};
`;

const MenuLinkWrapper = styled.div`
  display: block;
  height: 100%;
  padding-right: 25px;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding-right: 0;
    `
  )}

  &.is-open {
    &::before {
      content: '';
      position: absolute;
      left: -35px;
      right: -35px;
      top: 0;
      height: 55px;
      background-color: ${colors.ui.hcpDefault};
      z-index: -1;

      ${respondFrom(
        breakpoints.lg,
        css`
          display: none;
        `
      )};
    }
  }

  &.variant--red {
    &.is-open {
      &::before {
        background-color: ${colors.ui.patientDefault};

        ${respondFrom(
          breakpoints.lg,
          css`
            display: none;
          `
        )};
      }
    }
  }
`;

interface NavigationProps {
  variant: Variant;
  navUuid?: string;
  changeVariant: boolean;
}

interface MenuLinkProps {
  variant: Variant;
  noborder: 'true' | 'false';
}

interface AllProps {
  variant: Variant;
}

const Navigation = ({ variant, navUuid = undefined, changeVariant }: NavigationProps) => {
  const [hamburgerOpen, togglehamburger] = useState<boolean>(false);
  const [mobileSubmenuOpenIndex, toggleSubmenuSetIndex] = useState<number | null>(null);
  const data: any = variant === 'blue' ? patientsNavigationData() : hcpData();

  const handleOpenSubmenu = (index: number) => {
    if (mobileSubmenuOpenIndex === index) {
      toggleSubmenuSetIndex(null);
    } else {
      toggleSubmenuSetIndex(index);
    }
  };

  return (
    <NavigationWrapper variant={variant}>
      <Container>
        <NavigationInner>
          <NavigationLogo data={data} variant={variant} />

          <Hamburger src={hamburgerIcon} alt="" onClick={() => togglehamburger(true)} />

          <MenuWrapper className={`${hamburgerOpen ? 'is-open' : ''} variant--${variant}`}>
            <MenuMobileTitle>
              <img src={closeIcon} alt="" onClick={() => togglehamburger(false)} />
              <p>{data.menuLabel}</p>
            </MenuMobileTitle>

            <Menu variant={variant}>
              {data.menu.map((item, index) => (
                <li key={index} className={`${mobileSubmenuOpenIndex === index ? 'is-open' : ''}`}>
                  <MenuLinkWrapper
                    className={`variant--${variant} ${
                      mobileSubmenuOpenIndex === index ? 'is-open' : ''
                    }`}
                  >
                    <MenuLink
                      to={item.href}
                      variant={variant}
                      noborder={index === data.menu.length - 1 ? 'true' : 'false'}
                      className={`variant--${variant} ${
                        mobileSubmenuOpenIndex === index ? 'is-open' : ''
                      } ${navUuid && item.uuid && item.uuid === navUuid ? 'is-active' : ''}`}
                    >
                      {item.label}
                    </MenuLink>
                  </MenuLinkWrapper>

                  {item.menu && (item.menu as MenuData[]).length > 0 && (
                    <MenuMobileChevron
                      src={chevronRightIcon}
                      alt=""
                      onClick={() => handleOpenSubmenu(index)}
                      className={`${mobileSubmenuOpenIndex === index ? 'is-open' : ''}`}
                    />
                  )}

                  {item.menu && (item.menu as MenuData[]).length > 0 ? (
                    <Menu
                      className={`submenu ${mobileSubmenuOpenIndex === index ? 'is-open' : ''}`}
                      variant={variant}
                    >
                      {(item.menu as MenuData[]).map((item2, index2) => (
                        <li key={index2}>
                          <MenuLink
                            to={item2.href}
                            variant={variant}
                            noborder="true"
                            className={`submenu variant--${variant}`}
                          >
                            {item2.label}
                          </MenuLink>
                          <SubmenuLinkMobileIcon src={arrowTopRightIcon} alt="" />
                        </li>
                      ))}
                    </Menu>
                  ) : null}
                </li>
              ))}

              <MenuLinkOptions>
                <NavigationSwitch
                  variant={variant}
                  data={data.switchVersion}
                  changeVariant={changeVariant}
                />
                {data.language && <LanguageSelector variant={variant} lang={data.language} />}
              </MenuLinkOptions>
            </Menu>
          </MenuWrapper>
        </NavigationInner>
      </Container>
    </NavigationWrapper>
  );
};

export default Navigation;
